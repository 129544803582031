<template>
  <div class="text-center">
    <inline-svg
      class="mx-auto mt-10 mb-4"
      :src="require('../assets/images/icons/sad.svg')"
      alt="error"
    />
    <div class="text-3xl font-bold text-jooycar-blue-dark">
      Lo Sentimos
    </div>
    <div class="mb-2 font-semibold">
      Ha ocurrido un error en el proceso de pago
    </div>
    <div class="mb-10 text-sm">
      Te recomendamos revisar los datos ingresados e intentar nuevamente
    </div>
    <button
      class="block px-10 py-2 mx-auto mb-8 text-sm font-semibold text-white bg-jooycar-blue hover:bg-jooycar-blue-dark"
      @click="$router.push({ name: 'C2' })"
    >
      VOLVER A INTENTAR
    </button>
  </div>
</template>

<script>

export default {
};
</script>
